import { scrollToYOffset } from '../../helpers/scroll-to-helper';
import Page from '../page';

export default class SeminarPage extends Page {
  bind() {
    this._initializeFormScrollToEvent(); // todo : reusable widget ?
  }

  _initializeFormScrollToEvent() {
    const links = document.body.querySelectorAll('a[href="#formulaire"]');
    const anchor = document.body.querySelector('#formulaire');

    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        scrollToYOffset(anchor.getBoundingClientRect().top + window.scrollY);
      });
    });
  }
}
