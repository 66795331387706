import '../../sass/main.desktop.scss';

import Main from './main';
import { map } from '../map/map.desktop';

const __dcl = () => {
  new Main(map, 'desktop');
};

document.addEventListener('DOMContentLoaded', __dcl);
