import closest from '../../../lib/dom/closest';
import Page from '../../page';

export default class FrontendFaq extends Page {
  constructor(...args) {
    super(...args);

    this._retriveDOM();
  }

  _retriveDOM() {
    this.dom.ask = this.dom.page.querySelectorAll(
      '.wrapper-expand .title-topic',
    );
  }

  bind() {
    for (let index = 0; index < this.dom.ask.length; index++) {
      const element = this.dom.ask[index];
      element.addEventListener('click', this._switch.bind(this, element));
    }
  }

  _switch(el) {
    const parent = closest(el, '.wrapper-expand');
    if (parent.classList.contains('open')) {
      this._closeExpand(el, parent);
    } else {
      this._openExpand(el, parent);
    }
  }

  _openExpand(el, parent) {
    const currentExpand = parent.querySelector('.expand');
    const heightExpand = parent.querySelector('.inner-expand').clientHeight;

    currentExpand.style.height = heightExpand + 'px';
    parent.classList.add('open');
  }

  _closeExpand(el, parent) {
    const currentExpand = parent.querySelector('.expand');

    currentExpand.style.height = '0px';
    parent.classList.remove('open');
  }

  /**
   * init() {}
   * bind() {}
   * unbind() {}
   * resize(width, height) {}
   * _destroy() {}
   */
}
