import axios from 'axios';
import Page from '../../page';

export default class PaymentMethod extends Page {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.stripe = window.stripe;
    this.elements = this.stripe.elements();

    this.cardContainer = this.dom.page.querySelector('#card-element');
    this.errorContainer = this.dom.page.querySelector('#stripe-errors');
    this.stripeForm = this.dom.page.querySelector('#stripe-form');
    this.stripeSubmitButton = this.dom.page.querySelector('#stripe-submit-button');
    this.paymentMethodInput = this.dom.page.querySelector('#stripe_payment_paymentMethodId');
    this._paymentIntentRoute = this.stripeForm.getAttribute('data-payment-intent-route');
  }

  init() {
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.cardElement = this.elements.create('card', { style: style });
    this.cardElement.mount(this.cardContainer);
  }

  bind() {
    // Handle real-time validation errors from the card Element.
    this.cardElement.addEventListener('change', (event) => {
      if (event.error) {
        this._stripeError(event.error.message);
      } else {
        this._clearError();
      }
    });

    this.stripeForm.addEventListener('submit', (event) => {
      event.preventDefault();
      this._disableSubmit();

      this.stripe.createPaymentMethod('card', this.cardElement).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          this._stripeError(result.error.message);
          this._enableSubmit();
        } else {
          this._clearError();
          // Send the token to your server.
          this._requestPaymentIntent(result.paymentMethod.id);
        }
      });
    });
  }

  _requestPaymentIntent(paymentMethodId) {
    axios.post(this._paymentIntentRoute, { paymentMethodId }).then((response) => {
      this._handlePaymentIntent(response.data);
    });
  }

  _handlePaymentIntent(xhr) {
    if (xhr.error) {
      this._stripeError(xhr.error);
    } else {
      this.stripe.handleCardPayment(xhr.client_secret).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          this._stripeError(result.error.message);
          this._enableSubmit();
        } else {
          this._clearError();
          // Send the token to your server.
          console.log('card payment', result);
          this._submitSuccessfulPayment(result.paymentIntent.payment_method);
        }
      });
    }
  }

  _submitSuccessfulPayment(paymentMethodId) {
    this.paymentMethodInput.setAttribute('value', paymentMethodId);
    this.stripeForm.submit();
  }

  _disableSubmit() {
    this.previousSubmitText = this.stripeSubmitButton.innerText;
    this.stripeSubmitButton.innerText = 'Transaction en cours...';
    this.stripeSubmitButton.setAttribute('disabled', 'disabled');
  }

  _enableSubmit() {
    this.stripeSubmitButton.removeAttribute('disabled');
    this.stripeSubmitButton.innerText = this.previousSubmitText;
  }

  _stripeError(message) {
    this.errorContainer.textContent = message;
  }

  _clearError() {
    this._stripeError('');
  }
}
