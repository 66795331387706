import intlTelInput from 'intl-tel-input';
import Page from '../../page';
import '../../../../sass/components/phone-number-input/phone-number-input.scss';

export default class UserRegister extends Page {
  constructor(...args) {
    super(...args);
    this._dom = {};
    this._getDom();
    this._initPhoneNumberWidget();
    this._initCompanyNameDisplay();
    this._preventFormMultipleSubmit();
  }

  _getDom() {
    this._dom.form = this.dom.page.querySelector('form');
  }

  _initPhoneNumberWidget() {
    // search input
    const input = this._dom.form.querySelector('input[phone-number-input]');
    // if input
    if (!input) {
      return;
    }
    // lazy load utils script then launch
    import('intl-tel-input/build/js/utils').then(() => {
      const inputName = input.getAttribute('name');
      input.removeAttribute('name');
      intlTelInput(input, {
        initialCountry: 'fr', // add geoip lookup
        hiddenInput: inputName,
        customPlaceholder: () => 'Téléphone *',
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  _initCompanyNameDisplay() {
    const typeSelect = this._dom.form.querySelector('#registration_type');
    const companyNameInput = this._dom.form.querySelector('#registration_companyName');
    if (!typeSelect || !companyNameInput) {
      return;
    }
    const handler = () => {
      if (typeSelect.value === 'professional') {
        companyNameInput.closest('.form-group').style.display = 'block';
      } else {
        companyNameInput.closest('.form-group').style.display = 'none';
      }
    };
    typeSelect.addEventListener('change', () => handler());
    handler();
  }

  _preventFormMultipleSubmit() {
    this._dom.form.addEventListener('submit', function (e) {
      const submittedClass = 'js-submitted';
      if (this.classList.contains(submittedClass)) {
        e.preventDefault();
      } else {
        this.classList.add(submittedClass);
      }
    }, false);
  }
}
