import Page from '../../page';
import { scrollToYOffset } from '../../../helpers/scroll-to-helper';

export default class FrontendEvent extends Page {
  constructor(...args) {
    super(...args);
    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.cta_scroll = this.dom.component.querySelector(
      '.icon-wrapper-event',
    );
    this.dom.height_scroll = this.dom.component.querySelector('.ct--header');
  }

  bind() {
    // Auto Scroll
    if (this.dom.cta_scroll) {
      this.dom.cta_scroll.addEventListener('click', () => {
        scrollToYOffset(this.dom.height_scroll.getBoundingClientRect().height);
      });
    }
  }
}
