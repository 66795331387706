import components from './map.components.desktop';
import PaymentMethodDesktop from '../pages/customer/payment/payment-method.desktop';
import UserLoginDesktop from '../pages/user/login/login.desktop';
import UserRegisterDesktop from '../pages/user/register/register.desktop';
import UserTransmissionrequestDesktop from '../pages/user/transmission-request/transmission-request.desktop';
import FrontendFaqDesktop from '../pages/frontend/faq/faq.desktop';
import FrontendEventDesktop from '../pages/frontend/event/event.desktop';
import ErrorPageDesktop from '../pages/error/error-page.desktop';
import GenericDesktop from '../pages/generic/generic.desktop';
import SeminarDesktopPage from '../pages/seminar/seminar.desktop';

export const map = {
  components: components,
  pages: {
    user: {
      login: UserLoginDesktop,
      register: UserRegisterDesktop,
      transmissionrequest: UserTransmissionrequestDesktop,
    },
    frontend: {
      faq: FrontendFaqDesktop,
      event: FrontendEventDesktop,
    },
    customer: {
      'payment.payment-method': PaymentMethodDesktop,
    },
    page: {
      generic: GenericDesktop,
      seminar: SeminarDesktopPage,
      error: ErrorPageDesktop,
    },
  },
};
